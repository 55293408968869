import React from "react"
import { Layout, SEO } from "../components"
import ContectUsMain from "../components/contect-us/contect-us-main"

const ContactUs = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <ContectUsMain />
    </Layout>
  )
}

export default ContactUs
